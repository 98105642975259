import { format } from 'date-fns';
import {toZonedTime } from 'date-fns-tz';

export default class DateHelper {
    static DEFAULT_TIMEZONE = 'America/Sao_Paulo';

    static getToday() {
        const dateInSaoPaulo = toZonedTime(new Date(), DateHelper.DEFAULT_TIMEZONE);

        return format(dateInSaoPaulo, 'yyyy-MM-dd');
    }

    static todayWithPrefix(prefix: string) {
        return `${prefix}-${this.getToday()}`;
    }

    static getFormatedDayList(days: number, prefix: string = '') {
        const dayList = [];
        for (let i = 0; i < days; i++) {
            const day = new Date();
            day.setDate(day.getDate() - i);
            dayList.push(prefix + format(day, 'yyyy-MM-dd'));
        }
        return dayList;
    }
}