export type UserInfo = {
  peso: number;
  altura: number;
  sexo: 'masculino' | 'feminino';
};

export default function calcularQuantidadeAgua({ peso, altura, sexo }: UserInfo): number {
  const fatorBase = sexo === 'masculino' ? 37 : 32;
  const fatorAltura = altura > 160 ? 0.1 : 0.08;

  return (peso * fatorBase + altura * fatorAltura)
}