import {LuGlassWater} from "react-icons/lu";
import {HiMiniCog8Tooth} from "react-icons/hi2";
import { LuBarChartBig } from "react-icons/lu";

export enum MenuTabsEnum {
    WATER = 'WATER',
    SETTINGS = 'SETTINGS',
    CHARTS = 'CHARTS'
}

export default function MenuTabs({activeTab, setActiveTab, hasWaterConfig}: {
    activeTab: MenuTabsEnum,
    setActiveTab: (tab: MenuTabsEnum) => void,
    hasWaterConfig: boolean
}) {
    function selectTabWithConfigBlock(tab: MenuTabsEnum) {
        if (hasWaterConfig) {
            setActiveTab(tab)
            return;
        }

        alert('Configure seus dados antes de continuar')
    }

    return (
        <div style={{width: '100%', padding: '10px'}}>
            <div className="tabs is-centered is-large">
                <ul>
                    <li
                        className={`${activeTab === MenuTabsEnum.WATER ? "is-active" : ''}`}
                        onClick={() => selectTabWithConfigBlock(MenuTabsEnum.WATER)}>
                        <a>
                            <LuGlassWater/>
                        </a>
                    </li>
                    <li
                        className={`${activeTab === MenuTabsEnum.SETTINGS ? "is-active" : ''}`}
                        onClick={() => setActiveTab(MenuTabsEnum.SETTINGS)}>
                        <a>
                            <HiMiniCog8Tooth/>
                        </a>
                    </li>
                    <li
                        className={`${activeTab === MenuTabsEnum.CHARTS ? "is-active" : ''}`}
                        onClick={() => selectTabWithConfigBlock(MenuTabsEnum.CHARTS)}>
                        <a>
                            <LuBarChartBig />
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    )
}
