export type IngestaoHorario = {
  horario: string;
  quantidade: number;
};

export default function gerarHorariosIngestao(
    aguaTotal: number,
    horarioAcordar: string,
    horarioDormir: string
): IngestaoHorario[] {
  const horariosIngestao: IngestaoHorario[] = [];

  const [horaAcordar, minutoAcordar] = horarioAcordar.split(':').map(Number);
  const [horaDormir, minutoDormir] = horarioDormir.split(':').map(Number);
  const minutosAcordar = horaAcordar * 60 + minutoAcordar;
  const minutosDormir = horaDormir * 60 + minutoDormir;

  const minutosTotais = minutosDormir >= minutosAcordar
      ? minutosDormir - minutosAcordar
      : (1440 - minutosAcordar) + minutosDormir; // Considera a virada do dia

  const intervalos = Math.floor(minutosTotais / 30);

  const quantidadePorIntervalo = Math.round(aguaTotal / intervalos);

  let quantidadeAcumulada = 0;
  for (let i = 0; i < intervalos; i++) {
    const minutosAtual = (minutosAcordar + i * 30) % 1440; // Ajuste para 24h
    const hora = Math.floor(minutosAtual / 60).toString().padStart(2, '0');
    const minuto = (minutosAtual % 60).toString().padStart(2, '0');

    quantidadeAcumulada += quantidadePorIntervalo;

    horariosIngestao.push({
      horario: `${hora}:${minuto}`,
      quantidade: quantidadeAcumulada
    });
  }

  return horariosIngestao;
}
