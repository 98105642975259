import {useEffect, useMemo, useState} from "react";
import UserInfoRepository, {ConsumoAguaDiario} from "../backend/UserInfoRepository";
import {Area, AreaChart, Bar, BarChart, Legend, Tooltip, XAxis, YAxis} from "recharts";

export default function Charts() {
    const [waterConsumptionInfo, setWaterConsumptionInfo] = useState<ConsumoAguaDiario[]>([]);
    const [periodicity, setPeriodicity] = useState(7)
    const data = useMemo(() => waterConsumptionInfo
        .map((consumption) => {
            return {
                ...consumption,
                name: consumption.data,
            }
        }), [waterConsumptionInfo])

    useEffect(() => {
        UserInfoRepository
            .getAllDaysConsumingWaterDaysAgo(periodicity)
            .then(diaryConsumptionList => setWaterConsumptionInfo(diaryConsumptionList))
    }, [periodicity]);

    function handlePeriodicityChange(e: any) {
        setPeriodicity(Number(e.target.value))
    }

    return (
        <div style={{position: 'relative'}}>
            <h1>Acompanhe seu consumo de agua</h1>
            <br/>
            <div>
                <label>Periodicidade</label>
                <br/>
                <div className="select" style={{marginTop: '5px'}}>
                    <select
                        onChange={handlePeriodicityChange}
                        value={periodicity}
                        style={{width: '200px'}}
                    >
                        <option value={1}>hoje</option>
                        <option value={7}>7 dias</option>
                        <option value={14}>14 dias</option>
                        <option value={30}>30 dias</option>
                        <option value={90}>3 meses</option>
                    </select>
                </div>
            </div>
            <br/>
            <div style={{width: '100%'}}>
                <AreaChart
                    width={400}
                    height={300}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Area type="monotone" dataKey="aguaTotal" stroke="#8884d8" fill="#8884d8"/>
                    <Area type="monotone" dataKey="aguaConsumida" stroke="#8884d8" fill="#82ca9d"/>
                </AreaChart>
                <br/>
                <BarChart
                    width={400}
                    height={300}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <XAxis dataKey="name"/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="aguaConsumida" stackId="b" fill="#82ca9d"/>
                    <Bar dataKey="aguaTotal" stackId="a" fill="#8884d8"/>
                </BarChart>
            </div>

        </div>
    );
}