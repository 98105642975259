export default function Modal({children, onClose}: { children: React.ReactNode, onClose: () => void }) {
    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <section className="modal-card-body">
                    {children}
                </section>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={onClose}></button>
        </div>
    )
}