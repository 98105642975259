import React, { useEffect, useState } from 'react';
import UserInfoRepository, { UserInfo } from '../backend/UserInfoRepository';
import calcularQuantidadeAgua from "../backend/calculate";

const ConfiguracaoAgua: React.FC = () => {
    const [peso, setPeso] = useState<number>(0);
    const [altura, setAltura] = useState<number>(0);
    const [sexo, setSexo] = useState<'masculino' | 'feminino'>('masculino');
    const [horarioAcordar, setHorarioAcordar] = useState<string>('07:00');
    const [horarioDormir, setHorarioDormir] = useState<string>('23:00');
    const [aguaTotal, setAguaTotal] = useState<number>(2000);

    useEffect(() => {
        const carregarUserInfo = async () => {
            try {
                const userInfo = await UserInfoRepository.getUserInfo();
                if (userInfo) {
                    setPeso(userInfo.peso);
                    setAltura(userInfo.altura);
                    setSexo(userInfo.sexo);
                    setHorarioAcordar(userInfo.horarioAcordar);
                    setHorarioDormir(userInfo.horarioDormir);
                    setAguaTotal(userInfo.aguaTotal);
                }
            } catch (error) {
                alert('Erro ao carregar userInfo:');
            }
        };

        carregarUserInfo();
    }, []);

    useEffect(() => {
      if(peso === 0 || altura === 0) {
        return;
      }
      setAguaTotal(calcularQuantidadeAgua({peso, altura, sexo}));
    })

    const salvarInformacoes = async () => {
        const userInfo: UserInfo = {
            peso,
            altura,
            sexo,
            horarioAcordar,
            horarioDormir,
            aguaTotal,
        };

        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        try {
            await UserInfoRepository.saveOrUpdateUserInfo(userInfo);
            await UserInfoRepository.registrarIngestaoFirebase(0, aguaTotal);
            alert('Informações salvas com sucesso!');
            window.location.reload();
        } catch (error) {
            alert('Erro ao salvar informações no Firebase.');
        }
    };

    return (
        <div style={{width: '100%', padding: '10px', height: 'calc(100vh - 100px)'}}>
            <h1>Configuração de Consumo de Água</h1>
            <form onSubmit={(e) => e.preventDefault()}>
                <div>
                    <label>Peso (kg):</label>
                    <input
                        className='input is-link'
                        type="number"
                        value={peso}
                        onChange={(e) => setPeso(Number(e.target.value))}
                        required
                        />
                </div>
                <div>
                    <label>Altura (cm):</label>
                    <input
                        className='input is-link'
                        type="number"
                        value={altura}
                        onChange={(e) => setAltura(Number(e.target.value))}
                        required
                        />
                </div>
                <div>
                    <label>Sexo:</label>
                    <select value={sexo} onChange={(e) => setSexo(e.target.value as 'masculino' | 'feminino')}
                            className='input is-link'
                    >
                        <option value="masculino">Masculino</option>
                        <option value="feminino">Feminino</option>
                    </select>
                </div>
                <div>
                    <label>Horário de Acordar:</label>
                    <input
                        className='input is-link'
                        type="time"
                        value={horarioAcordar}
                        onChange={(e) => setHorarioAcordar(e.target.value)}
                        required
                        />
                </div>
                <div>
                    <label>Horário de Dormir:</label>
                    <input
                        className='input is-link'
                        type="time"
                        value={horarioDormir}
                        onChange={(e) => setHorarioDormir(e.target.value)}
                        required
                        />
                </div>
                <div>
                    <label>Água Total (ml):</label>
                    <input
                        className='input is-link'
                        type="number"
                        value={aguaTotal}
                        onChange={(e) => setAguaTotal(Number(e.target.value))}
                        required
                        />
                </div>
                <br/>
                <button className='button is-link' onClick={salvarInformacoes}>Salvar</button>
            </form>
        </div>
    )
}


export default ConfiguracaoAgua;