import React, {useEffect, useState} from 'react';
import './App.css';
import CopoAgua from "./pages/CopoAgua"
import ConfiguracaoAgua from "./pages/ConfiguracaoAgua"
import MenuTabs, {MenuTabsEnum} from "./components/MenuTabs";
import UserInfoRepository from "./backend/UserInfoRepository";
import Charts from "./pages/Charts";

function App() {
    const [hasWaterConfig, setHasWaterConfig] = useState(false);
    const [activeTab, setActiveTab] = useState(MenuTabsEnum.WATER);

    useEffect(() => {
        UserInfoRepository
            .getUserInfo()
            .then(userInfo => {
                if (userInfo) {
                    setHasWaterConfig(true);
                } else {
                    setActiveTab(MenuTabsEnum.SETTINGS)
                }
            })
    }, []);

    return (
        <div
            data-theme="light"
            className="App"
            style={{
                display: 'flex',
                // justifyContent: 'center',
                flexDirection: 'column',
                height: '100vh',
                flex: 1,
                // position: 'relative'

            }}>
            <div style={{width: '400px', marginTop: '20px', maxHeight: 'calc(100% - 100px)', overflowY: 'scroll'}}>
                {
                    activeTab === MenuTabsEnum.WATER && <CopoAgua/>
                }
                {
                    activeTab === MenuTabsEnum.SETTINGS && <ConfiguracaoAgua/>
                }
                {
                    activeTab === MenuTabsEnum.CHARTS && <Charts/>
                }
                {/*<div style={{width: '100%', height: '80px'}}></div>*/}
            </div>

            <MenuTabs activeTab={activeTab} setActiveTab={setActiveTab} hasWaterConfig={hasWaterConfig}/>
        </div>
    );
}

export default App;
