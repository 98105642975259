import React, {useEffect, useState} from 'react';
import UserInfoRepository from "../backend/UserInfoRepository";
import userInfoRepository from "../backend/UserInfoRepository";
import Modal from "../components/Modal";
import gerarHorariosIngestao from "../backend/ingestao";


type IngestaoHorario = {
    horario: string;
    quantidade: number;
};

const CopoAgua: React.FC = () => {
        const [horariosIngestao, setHorariosIngestao] = useState<IngestaoHorario[]>([]);
        const [aguaIngerida, setAguaIngerida] = useState<number>(0); // Quantidade de água ingerida
        const [aguaTotal, setAguaTotal] = useState<number>(0);
        const [showModal, setShowModal] = useState(false);
        const [showRemoveWaterModal, setShowRemoveWaterModal] = useState(false);
        const [aguaInput, setAguaInput] = useState<number>(0);

        useEffect(() => {
            UserInfoRepository.getUserInfo().then((userInfo) => {
                if (userInfo) {
                    const horarios = gerarHorariosIngestao(
                        userInfo.aguaTotal,
                        userInfo.horarioAcordar,
                        userInfo.horarioDormir
                    );
                    setHorariosIngestao(horarios);
                    setAguaTotal(userInfo.aguaTotal);
                }
            });
        }, []);

        useEffect(() => {
            userInfoRepository
                .getConsumoAgua()
                .then((consumo) => {
                    if (consumo) {
                        setAguaIngerida(consumo.aguaConsumida);
                    }
                });
        }, []);

        const registrarIngestao = (ml: number) => {
            setAguaIngerida((prev) => prev + ml)
            UserInfoRepository
                .registrarIngestaoFirebase(ml, aguaTotal)
                .catch(() => {
                    setAguaIngerida((prev) => prev - ml)
                })
                .finally(() => {
                    closeModal();
                    setAguaInput(0);
                })
        };

        function closeModal() {
            setShowModal(false);
            setShowRemoveWaterModal(false);
            setAguaInput(0);
        }

        const removerAgua = (ml: number) => {
            setAguaIngerida((prev) => prev - ml)
            UserInfoRepository
                .removerAgua(ml, aguaTotal)
                .catch(() => {
                    setAguaIngerida((prev) => prev + ml)
                })
                .finally(() => {
                    closeModal()
                    setAguaInput(0);
                });
        };

        const preenchimentoAltura = `${(aguaIngerida / aguaTotal) * 100}%`;

        return (
            <div style={{textAlign: 'center'}}>
                {showModal && (
                    <Modal onClose={() => closeModal()}>
                        <>
                            <div style={{display: 'grid', gridTemplateColumns: '2fr 2fr', gap: '5px'}}>
                                <button
                                    className='button is-link is-outlined'
                                    onClick={() => registrarIngestao(50)}
                                    style={{marginBottom: '10px'}}
                                >
                                    + 50ml
                                </button>
                                <button
                                    className='button is-link is-outlined'
                                    onClick={() => registrarIngestao(100)}
                                    style={{marginBottom: '10px'}}
                                >
                                    + 100ml
                                </button>
                            </div>
                            <div style={{display: 'grid', gridTemplateColumns: '2fr 2fr', gap: '5px'}}>
                                <button
                                    className='button is-link is-outlined'
                                    onClick={() => registrarIngestao(200)}
                                    style={{marginBottom: '10px'}}
                                >
                                    + 200ml
                                </button>
                                <button
                                    className='button is-link is-outlined'
                                    onClick={() => registrarIngestao(300)}
                                    style={{marginBottom: '10px'}}
                                >
                                    + 300ml
                                </button>
                            </div>
                            <div style={{display: 'flex', gap: '5px'}}>
                                <input
                                    className='input is-link'
                                    type="number"
                                    value={aguaInput}
                                    onChange={(e) => setAguaInput(Number(e.target.value))}
                                    required
                                    placeholder={"100ml"}
                                />
                                <button
                                    className='button is-success is-outlined'
                                    onClick={() => registrarIngestao(aguaInput)}
                                    style={{marginBottom: '10px'}}
                                >
                                    Adicionar
                                </button>
                            </div>
                        </>
                    </Modal>
                )}
                {showRemoveWaterModal && (
                    <Modal onClose={() => closeModal()}>
                        <>
                            <div style={{display: 'grid', gridTemplateColumns: '2fr 2fr', gap: '5px'}}>
                                <button
                                    className='button is-danger is-outlined'
                                    onClick={() => removerAgua(50)}
                                    style={{marginBottom: '10px'}}
                                >
                                    - 50ml
                                </button>
                                <button
                                    className='button is-danger is-outlined'
                                    onClick={() => removerAgua(100)}
                                    style={{marginBottom: '10px'}}
                                >
                                    - 100ml
                                </button>
                            </div>
                            <div style={{display: 'grid', gridTemplateColumns: '2fr 2fr', gap: '5px'}}>
                                <button
                                    className='button is-danger is-outlined'
                                    onClick={() => removerAgua(200)}
                                    style={{marginBottom: '10px'}}
                                >
                                    - 200ml
                                </button>
                                <button
                                    className='button is-danger is-outlined'
                                    onClick={() => removerAgua(300)}
                                    style={{marginBottom: '10px'}}
                                >
                                    - 300ml
                                </button>
                            </div>
                            <div style={{display: 'flex', gap: '5px'}}>
                                <input
                                    className='input is-warning'
                                    type="number"
                                    value={aguaInput}
                                    onChange={(e) => setAguaInput(Number(e.target.value))}
                                    required
                                    placeholder={"100ml"}
                                />
                                <button
                                    className='button is-link is-outlined'
                                    onClick={() => removerAgua(aguaInput)}
                                    style={{marginBottom: '10px'}}
                                >
                                    Remover
                                </button>
                            </div>
                        </>
                    </Modal>
                )}

                <h2>Meu Copo de Água</h2>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
                    <div
                        style={{
                            border: '1px solid blue',
                            width: '300px',
                            height: '550px',
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            backgroundColor: "white"
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                height: preenchimentoAltura,
                                backgroundColor: 'rgba(0, 123, 255, 0.5)',
                                transition: 'height 0.3s ease-in-out',
                            }}
                        ></div>

                        {horariosIngestao.map((ingestao, index) => (
                            <div
                                key={index}
                                style={{
                                    position: 'absolute',
                                    bottom: `${(index / horariosIngestao.length) * 100}%`,
                                    width: '100%',
                                    borderTop: '1px solid blue',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0 5px',
                                    fontSize: '12px',
                                    background: 'rgba(255, 255, 255, 0.6)',
                                    color: '#000'
                                }}
                            >
                                <span>{ingestao.horario}</span>
                                <span>{ingestao.quantidade} ml</span>
                            </div>
                        ))}
                    </div>
                </div>
                <br/>

                <p>Água ingerida: {aguaIngerida} ml / {aguaTotal} ml</p>
                <br/>
                <button className='button is-link is-outlined' onClick={() => setShowModal(true)}
                        style={{marginBottom: '10px'}}>
                    Registrar Agua
                </button>
                <button className='button is-danger is-outlined' onClick={() => setShowRemoveWaterModal(true)}
                        style={{marginBottom: '10px', marginLeft: '5px'}}>
                    Remover Agua
                </button>
            </div>
        );
    }
;

export default CopoAgua;