import DateHelper from "./Date";

export type UserInfo = {
    peso: number;
    altura: number;
    sexo: 'masculino' | 'feminino';
    horarioAcordar: string;
    horarioDormir: string;
    aguaTotal: number;
};

export interface ConsumoAguaDiario {
    data: string;
    aguaTotal: number;
    aguaConsumida: number;
}

class UserInfoRepository {
    private documentId = 'configuracaoAgua';
    private collectionName = 'userInfo';
    private waterCollectionName = 'waterConsumption';

    async saveOrUpdateUserInfo(userInfo: UserInfo): Promise<void> {
        try {
            localStorage.setItem(`${this.collectionName}-${this.documentId}`, JSON.stringify(userInfo))
        } catch (error) {
            alert('Erro ao salvar ou atualizar userInfo');
            throw error;
        }
    }

    async getAllDaysConsumingWaterDaysAgo(days: number): Promise<ConsumoAguaDiario[]> {
        const dayList = DateHelper.getFormatedDayList(days, 'waterConsumption-');
        return dayList.map(key => {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value) as ConsumoAguaDiario;
            }

            return {
                data: key.replace('waterConsumption-', ''),
                aguaTotal: 0,
                aguaConsumida: 0,
            };
        }).reverse() as ConsumoAguaDiario[]
    }

    async getUserInfo(): Promise<UserInfo | null> {
        try {
            const userDoc = localStorage.getItem(`${this.collectionName}-${this.documentId}`);
            if (userDoc) {
                return JSON.parse(userDoc) as UserInfo;
            }
            return null;
        } catch (error) {
            alert('Erro ao obter userInfo');
            throw error;
        }
    }

    async saveOrUpdateConsumoAgua(consumoAguaDiario: ConsumoAguaDiario): Promise<void> {
        try {
            localStorage.setItem(DateHelper.todayWithPrefix(this.waterCollectionName), JSON.stringify(consumoAguaDiario));
        } catch (error) {
            console.error('Erro ao salvar ou atualizar consumo de água', error);
            throw error;
        }
    }

    async getConsumoAgua(): Promise<ConsumoAguaDiario | null> {
        try {
            const userDoc = localStorage.getItem(DateHelper.todayWithPrefix(this.waterCollectionName))

            if (userDoc) {
                return JSON.parse(userDoc) as ConsumoAguaDiario;
            }
            return null;
        } catch (error) {
            console.error('Erro ao obter consumo de água', error);
            throw error;
        }
    }

    registrarIngestaoFirebase = async (ml: number, aguaTotal: number) => {
        try {
            const consumo = await this.getConsumoAgua();
            let aguaConsumida = consumo ? consumo.aguaConsumida : 0;

            aguaConsumida += ml;
            await this.atualizarConsumoAguaFirebase(aguaConsumida, aguaTotal);
        } catch (error) {
            alert('Erro ao registrar ingestão de água:');
        }
    }

    removerAgua = async (ml: number, aguaTotal: number) => {
        try {
            const consumo = await this.getConsumoAgua();
            let aguaConsumida = consumo ? consumo.aguaConsumida : 0;

            aguaConsumida -= ml;
            await this.atualizarConsumoAguaFirebase(aguaConsumida, aguaTotal);
        } catch (error) {
            alert('Erro ao registrar ingestão de água:');
        }
    }

    atualizarConsumoAguaFirebase = async (aguaConsumida: number, aguaTotal: number) => {
        const consumoDiario: ConsumoAguaDiario = {
            data: DateHelper.getToday(),
            aguaTotal,
            aguaConsumida,
        };

        try {
            await this.saveOrUpdateConsumoAgua(consumoDiario);
            console.log('Consumo de água atualizado no Firebase');
        } catch (error) {
            console.error('Erro ao atualizar consumo de água no Firebase:', error);
        }
    };
}


export default new UserInfoRepository();